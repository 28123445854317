import React, { useState, useEffect } from "react"
import update from "immutability-helper"
import { CustomOptionsPanelItem } from "./CustomOptionsPanelItem"
import { DragDropContext, Droppable } from "@hello-pangea/dnd"

export function CustomOptionsPanel({
  columns,
  columnOrder,
  setColumnOrder,
  columnVisibility,
  ...props
}) {
  const [sortedColumns, setSortedColumns] = useState([])

  useEffect(() => {
    if (columns?.length > 0 && columnOrder?.length > 0) {
      const _sortedColumns = columnOrder
        .map((columnId) => columns.find((column) => column.id === columnId))
        .filter(Boolean);
      setSortedColumns(_sortedColumns);
    } else {
      setSortedColumns([]);
    }
  }, [columns, columnOrder]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const newColumnOrder = update(columnOrder, {
      $splice: [
        [source.index, 1],
        [destination.index, 0, columnOrder[source.index]],
      ],
    });
    setColumnOrder(newColumnOrder);
  };

  if (!columns?.length || !columnOrder?.length) {
    return <div className="p-4 text-white">No columns available</div>;
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable-1'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={
                "gap-2 bg-[#131b1f] p-[15px] border border-solid border-[#213843]"
              }
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                top: "auto !important",
              }}
            >
              {sortedColumns.map((column, i) => (
                <CustomOptionsPanelItem
                  index={i}
                  column={column}
                  propsIndex={i}
                  key={column.id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
