import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { statuses, getStatusFromValue } from "../statuses"

import { useQuery, useQueryClient } from "@tanstack/react-query"
import { fetcher } from "../fetcher"
import { Status } from "../components/status"
import { Popover, PopoverTrigger, PopoverContent } from "../components/popover"
import {
  shippingResponsibilities,
  getShippingResponsibility,
} from "../shippingResponsibilities"
import { isEstimatesEmpty, isInvoicesEmpty } from "../utils/helpers"

import withPageContexts from "../hocs/withPageContexts"
import { useDetailsContext } from "../contexts/DetailsContext"
import { useUserRole } from "../contexts/UserContext"
import { ROLES, hasRoleAtLeast, hasRoleAtMost } from "../utils/roles"
import useViewStackWithModal from "../utils/useViewStack"
import GenericModal from "../components/GenericModal"
import { CustomCommonTable } from "../components/CustomCommonTable"
import IconClipboard from "../components/icons/icon-clipboard"

function getStatusClassName(status) {
  const statusColors = {
    pending: "bg-status-pending",
    awarded: "bg-status-awarded",
    "invoice-ready": "bg-status-invoiceready",
    invoicing: "bg-status-invoicing",
    invoiced: "bg-status-invoiced",
    canceled: "bg-status-canceled",
    completed: "bg-status-completed",
  }

  return statusColors[status] || "default-class"
}

function ManualQBOModal({ openModal, popAllViewsAndClear, data, refetch }) {
  const [qboUrl, setQboUrl] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!qboUrl) return

    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_API_URL}/api/qbo/receive_qbo_status/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${import.meta.env.VITE_DJANGO_SECRET_KEY}`
        },
        body: JSON.stringify({
          project_name: data?.project_name,
          status: "success",
          message: "Project created successfully",
          project_url: qboUrl
        })
      });
      
      const result = await response.json();

      if (response.ok) {
        popAllViewsAndClear()
        refetch()
        setShowRefreshButton(false)
      } else {
        console.error("Failed to update QBO status:", result.error || response.statusText)
      }
    } catch (error) {
      console.error("Error updating QBO status:", error)
    }
  }

  return (
    <GenericModal
      modalName={"manualQBO"}
      openModal={openModal}
      popAllViewsAndClear={popAllViewsAndClear}
    >
      <div className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'>
        <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
          MANUAL QBO LINK
        </div>
        <form onSubmit={handleSubmit} className='w-full'>
          <div className='mb-4'>
            <label className='block text-white text-sm font-bold mb-2' htmlFor='projectName'>
              Project Name
            </label>
            <div className='flex items-center justify-between tw-dropdown-text w-full text-start z-20 pr-[10px] bg-[#31404e80] h-[35px] px-2.5'>
              <div className='w-full text-ellipsis overflow-hidden whitespace-nowrap text-white'>
                {data?.name_string || ''}
              </div>
              <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(data?.name_string || '');
                    }}
                    className='ml-2 pr-2 relative'
                  >
              <IconClipboard
              width={16}
                className="cursor-pointer"
              />
              </button>
            </div>
          </div>
          <div className='mb-4'>
            <label className='block text-white text-sm font-bold mb-2' htmlFor='customer'>
              Customer
            </label>
            <div className='flex items-center justify-between tw-dropdown-text w-full text-start z-20 pr-[10px] bg-[#31404e80] h-[35px] px-2.5'>
              <div className='w-full text-ellipsis overflow-hidden whitespace-nowrap text-white'>
                {data?.client_name || ''}
              </div>
              <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigator.clipboard.writeText(data?.client_name || '');
                    }}
                    className='ml-2 pr-2 relative'
                  >
              <IconClipboard
              width={16}
                className="cursor-pointer"
              />
              </button>
            </div>
          </div>
          <div className='mb-4'>
            <label className='block text-white text-sm font-bold mb-2' htmlFor='qboUrl'>
              QuickBooks URL
            </label>
            <div className='flex items-center bg-[#31404e80] h-[35px]'>
              <input
                className='w-full px-2.5 font-2024-textfield-text font-[number:var(--2024-textfield-text-font-weight)] text-white text-[length:var(--2024-textfield-text-font-size)] tracking-[var(--2024-textfield-text-letter-spacing)] leading-[var(--2024-textfield-text-line-height)] whitespace-nowrap [font-style:var(--2024-textfield-text-font-style)] bg-transparent border-none outline-none'
                id='qboUrl'
                type='url'
                placeholder='Enter QuickBooks URL'
                value={qboUrl}
                onChange={(e) => setQboUrl(e.target.value)}
                required
              />
            </div>
          </div>
          <div className='flex items-center justify-end gap-2.5'>
            <button
              type='button'
              className='bg-transparent hover:bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline border border-white'
              onClick={popAllViewsAndClear}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='bg-variable-collection-neongreen hover:bg-green-600 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              disabled={!qboUrl}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  )
}

function PageComponent(props) {
  const {
    defaultQBOColumns,
    defaultDropboxColumns,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    columnQBOVisibility,
    setColumnQBOVisibility,
    columnDropboxVisibility,
    setColumnDropboxVisibility,
    columnSizing,
    setColumnSizing,
    columnDropboxOrder,
    // setColumnDropboxOrder,
    columnQBOOrder,
    // setColumnQBOOrder,
    // defaultMilestonesColumns,
    // defaultCrewColumns,
    // defaultShipmentColumns,
    // defaultMilestonesColumnVisibility,
    // defaultCrewColumnVisibility,
    // defaultShipmentColumnVisibility,
    tableSelected,
    setTableSelected,
  } = useDetailsContext()

  const { role } = useUserRole()

  const [selectedData, setSelectedData] = useState(null)
  const [selectedColumns, setSelectedColumns] = useState(null)
  const [selectedVisibility, setSelectedVisibility] = useState(null)
  const [rowSelection, setRowSelection] = useState({})
  const [qboStatus, setQBOStatus] = useState(Status.IN_PROGRESS)
  const [dropboxStatus, setDropboxStatus] = useState(Status.IN_PROGRESS)
  const [mergedQBOData, setMergedQBOData] = useState([])
  const [selectedStatus, setSelectedStatus] = useState(statuses?.[0])
  const [notes, setNotes] = useState("")
  const [selectedPO, setSelectedPO] = useState(shippingResponsibilities[0])
  // const [isModalOpen, setIsModalOpen] = useState(false)
  const [pendingStatus, setPendingStatus] = useState("")
  const [height, setHeight] = useState(245)
  const [isDragging, setIsDragging] = useState(false)
  // const [activeCalendarCategory, setActiveCalendarCategory] =
  //   useState("milestones")
  // const [activeMapCategory, setActiveMapCategory] = useState("equipment")
  // const [calendarIndexSelected, setCalendarIndexSelected] = useState(0)
  // const [mapIndexSelected, setMapIndexSelected] = useState(0)
  // useEscapeKey([setIsModalOpen], false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const uuid = queryParams.get("uuid")

  // const closeModal = () => setOpenModal(null)

  // const { data: milestonesData, refetch: milestonesRefetch } = useQuery({
  //   queryKey: ["getMilestones"],
  //   queryFn: () => fetcher("/api/calendar/milestones"),
  // })

  const {
    pushView,
    popView,
    popAllViewsAndClear,
    openModal,
    selectedTemplate,
    setSelectedTemplate,
  } = useViewStackWithModal()

  const [poNumber, setPONumber] = useState("")
  const [pendingPONumber, setPendingPONumber] = useState("")

  // const [milestones, setMilestones] = useState([])
  // const [filteredMilestones, setFilteredMilestones] = useState([])
  // const [filteredMilestonesSearch, setFilteredMilestonesSearch] = useState("")

  const patchShippingResponsibilities = async (shipping) => {
    const response = await fetcher(`/api/project/${uuid}/`, "PATCH", {
      shipping_responsibility: shipping.title,
    })
    refetch()
  }

  // useEffect(() => {
  //   if (milestonesData) {
  //     setMilestones([
  //       ...milestonesData.map((item, index) => ({
  //         title: `${item.name}`,
  //         label: `${item.name}`,
  //         uuid: item.uuid,
  //         key: index,
  //       })),
  //     ])
  //   }
  // }, [milestonesData])

  // useEffect(() => {
  //   if (!filteredMilestonesSearch) {
  //     setFilteredMilestones(milestones)
  //     return
  //   }
  //   const filtered = milestones.filter((milestones) => {
  //     return milestones.title
  //       .toLowerCase()
  //       .includes(filteredMilestonesSearch.toLowerCase())
  //   })

  //   setFilteredMilestones(filtered)
  // }, [milestones, filteredMilestonesSearch])

  const queryClient = useQueryClient()

  useEffect(() => {
    setQBOStatus(Status.IN_PROGRESS)

    return () => {
      queryClient.invalidateQueries({ queryKey: ["getProject"] })
    }
  }, [queryClient, uuid])

  const { data, isLoading, refetch, isError, status, isFetching } = useQuery({
    queryKey: ["getProject", uuid],
    queryFn: () => fetcher(`/api/project/${uuid}`),
    staleTime: 0,
    keepPreviousData: true,
    enabled:
      qboStatus === Status.IN_PROGRESS || dropboxStatus === Status.IN_PROGRESS,
  })

  useEffect(() => {
    if (!isLoading && !isError) {
      setSelectedStatus(statuses[getStatusFromValue(data.status)])
      setSelectedPO(
        shippingResponsibilities[
          getShippingResponsibility(data.shipping_responsibility)
        ]
      )
      setNotes(data.user_note?.note || '')
      setPendingPONumber(data.po_number)
    }
  }, [data, isLoading, isError])
  useEffect(() => {}, [height, isDragging])
  const startDragging = (e) => {
    setIsDragging(true)
    document.body.style.userSelect = "none"
    document.addEventListener("mousemove", onDrag)
    document.addEventListener("mouseup", stopDragging)
  }

  useEffect(() => {
    if (hasRoleAtMost(role, ROLES.PROJECTMANAGER)) {
      return
    }
    if (tableSelected === 0) {
      setSelectedData(mergedQBOData)
      setSelectedColumns(defaultQBOColumns)
      setSelectedVisibility(columnQBOVisibility)
    } else if (tableSelected === 1) {
      setSelectedData(data?.dropbox_folders)
      setSelectedColumns(defaultDropboxColumns)
      setSelectedVisibility(columnDropboxVisibility)
    }
  }, [
    tableSelected,
    mergedQBOData,
    data,
    columnQBOVisibility,
    columnDropboxVisibility,
    role,
    defaultQBOColumns,
    defaultDropboxColumns,
  ])

  const onDrag = (e) => {
    const newHeight = height + e.movementY
    setHeight((prevHeight) => Math.max(prevHeight + e.movementY, 245))
  }

  const stopDragging = () => {
    setIsDragging(false)
    document.body.style.userSelect = ""
    document.removeEventListener("mousemove", onDrag)
    document.removeEventListener("mouseup", stopDragging)
  }

  function isDropboxFileListEmpty() {
    return (
      dropboxStatus === Status.FAILED || data?.dropbox_folders?.length === 0
    )
  }

  const handleStatusClick = (index) => () => {
    setPendingStatus(statuses[index].title)
    pushView("statusConfirmation")
  }
  async function handleStatusChange(e) {
    e.preventDefault()
    console.log("pending: ", pendingStatus)
    const response = await fetcher(`/api/project/${uuid}/`, "PATCH", {
      status: pendingStatus,
    })
    popAllViewsAndClear()
    refetch()
  }
  function StatusConfirmation() {
    return (
      <GenericModal
        modalName={"statusConfirmation"}
        openModal={openModal}
        popAllViewsAndClear={popAllViewsAndClear}
      >
        <form
          className='flex flex-col w-[512px] items-start gap-2.5 p-5 relative bg-[#081118]'
          onSubmit={handleStatusChange}
        >
          <div className='relative self-stretch mt-[-1.00px] font-2024-panel-header font-[number:var(--2024-panel-header-font-weight)] text-white text-[length:var(--2024-panel-header-font-size)] tracking-[var(--2024-panel-header-letter-spacing)] leading-[var(--2024-panel-header-line-height)] [font-style:var(--2024-panel-header-font-style)]'>
            CHANGE STATUS
          </div>
          <div className='gap-5 flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
            <div className='gap-[5px] flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]'>
              <div className='relative self-stretch text-left font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] [font-style:var(--2024-filter-option-font-style)]'>
                <div className='text-center font-2024-filter-option font-normal tracking-[var(--2024-panel-button-letter-spacing)]'>
                  Are you sure you want to change the status of the project?
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-start justify-end gap-2.5 relative self-stretch w-full flex-[0_0_auto]'>
            <button
              onClick={(e) => {
                e.preventDefault()
                popView()
              }}
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative border border-solid border-[#d9d9d9]'
            >
              <div className='relative w-fit font-2024-panel-button font-[number:var(--2024-panel-button-font-weight)] text-white text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] leading-[var(--2024-panel-button-line-height)] whitespace-nowrap [font-style:var(--2024-panel-button-font-style)]'>
                CANCEL
              </div>
            </button>
            <button
              type='submit'
              className='all-[unset] box-border flex flex-col w-[90px] h-8 items-center justify-center gap-2.5 relative bg-variable-collection-neongreen'
            >
              <div className='relative w-fit font-2024-panel-button text-black text-[length:var(--2024-panel-button-font-size)] tracking-[var(--2024-panel-button-letter-spacing)] whitespace-nowrap]'>
                OK
              </div>
            </button>
          </div>
        </form>
      </GenericModal>
    )
  }

  const [showRefreshButton, setShowRefreshButton] = useState(false);

  useEffect(() => {
    if (data?.task_statuses && data.task_statuses.length > 0) {
      setShowRefreshButton(data.task_statuses[0].status === "FAILED");
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className='flex w-100 h-full items-center justify-center'>
        <img
          src='static/tw-logo-clean.png'
          alt='logo'
          width={64}
          height={64}
          className='fade-in-out'
        />
      </div>
    )
  } else {
    return (
      <div className=' p-7.5'>
        <div className='flex items-center gap-5 relative'>
          <div className='flex items-center gap-5 relative flex-[0_0_auto]'>
            <div className='relative w-fit mt-[-1.00px] font-2024-page-title font-[number:var(--2024-page-title-font-weight)] text-white text-[length:var(--2024-page-title-font-size)] tracking-[var(--2024-page-title-letter-spacing)] leading-[var(--2024-page-title-line-height)] whitespace-nowrap [font-style:var(--2024-page-title-font-style)]'>
              Projects
            </div>
            <img
              className='relative w-[9.7px] h-[19.82px]'
              alt='Chevron'
              src='static/chevron.svg'
            />
            <div className='relative w-fit mt-[-1.00px] font-2024-page-title font-[number:var(--2024-page-title-font-weight)] text-white text-[length:var(--2024-page-title-font-size)] tracking-[var(--2024-page-title-letter-spacing)] leading-[var(--2024-page-title-line-height)] whitespace-nowrap [font-style:var(--2024-page-title-font-style)]'>
              {data?.project_name
                ? // ? `${data.project_id} - ${data.project_name}`
                  data.project_name
                : "Loading..."}
            </div>
          </div>
          <div className='flex items-center gap-2 relative'>
            <img
              className='relative w-6 h-6 cursor-pointer'
              alt='Google calendar'
              src='static/img/google-calendar.png'
              onClick={() => {
                window.open(
                  "https://calendar.google.com",
                  "_blank",
                  "noopener,noreferrer"
                )
              }}
            />
            <img
              className={`relative w-6 h-6 ${
                !data?.quickbook_projects?.[0]?.project_url ? "!pointer-events-none opacity-50" : ""
              } cursor-pointer`}
              alt='Quickbooks'
              src='static/img/quickbooks.png'
              onClick={() => {
                window.open(
                  data?.quickbook_projects?.[0]?.project_url,
                  "_blank",
                  "noopener,noreferrer"
                )
              }}
            />
            <img
              className='relative w-[39.83px] h-6 !pointer-events-none opacity-50'
              alt='Monday'
              src='static/img/monday.png'
            />
            <img
              className={`relative w-[23.95px] ${
                !data?.harvest_project?.[0]?.harvest_project_url ? "!pointer-events-none opacity-50" : ""
              } cursor-pointer`}
              alt='Harvest'
              src='static/img/harvest.svg'
              onClick={() => {
                window.open(
                  data?.harvest_project?.[0]?.harvest_project_url,
                  "_blank",
                  "noopener,noreferrer"
                )
              }}
            />
            <img
              className={`relative w-[25.76px] h-6 cursor-pointer ${
                !data?.dropbox_folder_created &&
                "pointer-events-none opacity-50"
              }`}
              alt='Dropbox'
              src='static/img/dropbox.svg'
              onClick={() => {
                window.open(
                  data?.dropbox_folders?.[0],
                  "_blank",
                  "noopener,noreferrer"
                )
              }}
            />
            <img
              className='relative w-[42.5px] h-6 !pointer-events-none opacity-50'
              alt='Current RMS'
              src='static/img/currentrms.png'
            />
            <img
              className='relative w-[24.98px] h-6 !pointer-events-none opacity-50'
              alt='Confluence'
              src='static/img/confluence.png'
            />
          </div>
        </div>
        <div className='flex items-center gap-2.5 relative mt-[10px] h-[57px]'>
          <div className='flex flex-col items-start justify-center gap-[5px] p-2.5 relative flex-[0_0_auto] bg-variable-collection-2024-panel-background-color h-full p-[10px]'>
            <div className='flex items-center gap-0.5 relative flex-[0_0_auto]'>
              <img
                className='relative flex-[0_0_auto]'
                alt='pound'
                src='static/pound.svg'
              />
              <div className='font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                PROJECT ID
              </div>
            </div>
            <div className='relative w-fit font-2024-project-box-label font-[number:var(--2024-project-box-label-font-weight)] text-white text-[length:var(--2024-project-box-label-font-size)] tracking-[var(--2024-project-box-label-letter-spacing)] leading-[var(--2024-project-box-label-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-label-font-style)] h-full'>
              {data?.project_id}
            </div>
          </div>
          <div className='flex flex-col items-start justify-center gap-[5px] p-2.5 relative flex-[0_0_auto] bg-variable-collection-2024-panel-background-color h-full p-[10px]'>
            <div className='flex items-center gap-0.5 relative flex-[0_0_auto]'>
              <img
                className='relative flex-[0_0_auto]'
                alt='client'
                src='static/person.svg'
              />
              <div className='relative w-fit font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                CLIENT
              </div>
            </div>
            <div className='relative w-fit font-2024-project-box-label font-[number:var(--2024-project-box-label-font-weight)] text-white text-[length:var(--2024-project-box-label-font-size)] tracking-[var(--2024-project-box-label-letter-spacing)] leading-[var(--2024-project-box-label-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-label-font-style)] h-full'>
              {data?.client_name}
            </div>
          </div>
          <div className='flex flex-col items-start justify-center gap-[5px] p-2.5 relative flex-[0_0_auto] bg-variable-collection-2024-panel-background-color h-full p-[10px]'>
            <div className='flex items-center gap-0.5 relative flex-[0_0_auto]'>
              <img
                className='relative flex-[0_0_auto]'
                alt='Icon'
                src='static/calendar.svg'
              />
              <div className='relative w-fit font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                EVENT
              </div>
            </div>
            <div className='font-2024-project-box-label font-[number:var(--2024-project-box-label-font-weight)] text-[length:var(--2024-project-box-label-font-size)] relative w-fit text-white tracking-[var(--2024-project-box-label-letter-spacing)] leading-[var(--2024-project-box-label-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-label-font-style)] h-full'>
              {data?.project_event_name}
            </div>
          </div>

          <Popover className='h-full'>
            <PopoverTrigger disabled={hasRoleAtMost(role, ROLES.USER)}>
              <div
                className={`flex items-center gap-5 p-2.5 relative flex-[0_0_auto] ${getStatusClassName(
                  selectedStatus?.title
                )}`}
              >
                <div className='flex flex-col items-start justify-center gap-[5px] relative flex-[0_0_auto]'>
                  <div className='flex items-center gap-0.5 relative flex-[0_0_auto]'>
                    <img
                      className='relative flex-[0_0_auto]'
                      alt='status'
                      src='static/status.svg'
                    />
                    <div className='relative w-fit mt-[-0.37px] font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                      STATUS
                    </div>
                  </div>
                  <div className='relative w-fit font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] whitespace-nowrap [font-style:var(--2024-table-body-font-style)]'>
                    {selectedStatus?.label}
                  </div>
                </div>
                <img
                  className='relative w-[10.83px] h-[6.83px] mr-[-1.41px]'
                  alt='Chevron down'
                  src='static/chevron-down.svg'
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className='Popover' style={{ zIndex: 10 }}>
              <div className='flex flex-col items-start gap-5 p-2.5 text-left relative bg-variable-collection-2024-project-panel'>
                <button
                  className='relative self-stretch mt-[-1.00px] font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(0)}
                >
                  Pending
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(1)}
                >
                  Awarded
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(2)}
                >
                  Invoice Ready
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(3)}
                >
                  Invoicing
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(4)}
                >
                  Invoiced
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(5)}
                >
                  Completed
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={handleStatusClick(6)}
                >
                  Canceled
                </button>
              </div>
            </PopoverContent>
          </Popover>

          <Popover className='h-full'>
            <PopoverTrigger disabled={hasRoleAtMost(role, ROLES.USER)}>
              <div
                className={`flex items-center gap-5 p-2.5 relative flex-[0_0_auto] bg-variable-collection-2024-panel-background-color shadow-[0px_0px_4px_#4bc34b40]`}
              >
                <div className='flex flex-col items-start justify-center gap-[5px] relative flex-[0_0_auto]'>
                  <div className='flex items-center gap-0.5 relative flex-[0_0_auto]'>
                    <img
                      className='relative flex-[0_0_auto]'
                      alt='Icon'
                      src='static/shipping.svg'
                    />
                    <div className='relative w-fit mt-[-0.37px] font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                      SHIPPING
                    </div>
                  </div>
                  <div className='relative w-fit font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] whitespace-nowrap [font-style:var(--2024-table-body-font-style)]'>
                    {data?.shipping_responsibility === "thumbwar" ? "THUMBWAR" : data?.shipping_responsibility?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                  </div>
                </div>
                <img
                  className='relative w-[10.83px] h-[6.83px] mr-[-1.41px]'
                  alt='Chevron down'
                  src='static/chevron-down.svg'
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className='Popover' style={{ zIndex: 10 }}>
              <div className='flex flex-col items-start gap-5 p-2.5 text-left relative bg-variable-collection-2024-project-panel'>
                <button
                  className='relative self-stretch mt-[-1.00px] font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={async (e) => {
                    await patchShippingResponsibilities(
                      shippingResponsibilities[0]
                    )
                  }}
                >
                  Customer
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={async (e) => {
                    await patchShippingResponsibilities(
                      shippingResponsibilities[1]
                    )
                  }}
                >
                  THUMBWAR
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={async (e) => {
                    await patchShippingResponsibilities(
                      shippingResponsibilities[2]
                    )
                  }}
                >
                  Other
                </button>
                <button
                  className='relative self-stretch font-2024-table-body font-[number:var(--2024-table-body-font-weight)] text-white text-[length:var(--2024-table-body-font-size)] tracking-[var(--2024-table-body-letter-spacing)] leading-[var(--2024-table-body-line-height)] [font-style:var(--2024-table-body-font-style)]'
                  onClick={async (e) => {
                    await patchShippingResponsibilities(
                      shippingResponsibilities[3]
                    )
                  }}
                >
                  None
                </button>
              </div>
            </PopoverContent>
          </Popover>

          <div
            className={`flex flex-col items-start justify-center gap-[5px] p-2.5 relative flex-[0_0_auto] transition-bg ${
              pendingPONumber != poNumber
                ? "bg-variable-collection-2024-table-header-selected"
                : "bg-[#19262e]"
            } h-full`}
          >
            <div className='flex overflow-hidden items-center gap-0.5 relative flex-[0_0_auto]'>
              <img
                className='relative flex-[0_0_auto]'
                alt='Icon'
                src='static/ponumber.svg'
              />
              <div className='relative w-fit font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                PO NUMBER
              </div>
            </div>
            <div className='flex items-center gap-2.5 relative flex-[0_0_auto]'>
              <div className='relative w-fit mt-[-1.00px] font-2024-project-box-label font-[number:var(--2024-project-box-label-font-weight)] text-white text-[length:var(--2024-project-box-label-font-size)] tracking-[var(--2024-project-box-label-letter-spacing)] leading-[var(--2024-project-box-label-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-label-font-style)]'>
                <input
                  className='bg-variable-collection-2024-table-header'
                  disabled={hasRoleAtMost(role, ROLES.USER)}
                  onChange={(e) => {
                    setPendingPONumber(e.target.value)
                  }}
                  onKeyUp={async (e) => {
                    if (e.key === "Enter" && pendingPONumber != poNumber) {
                      const response = await fetcher(
                        `/api/project/${data.uuid}/`,
                        "PATCH",
                        {
                          po_number: pendingPONumber,
                        }
                      )

                      refetch()
                    }
                  }}
                  value={pendingPONumber}
                />
              </div>
              <img
                className='relative w-[13.23px] h-[13.23px] mr-[-0.50px]'
                alt='Edit'
                src='static/edit.svg'
              />
            </div>
          </div>
          <div className='flex flex-col items-center gap-px relative flex-[0_0_auto] h-[56px]'>
            <div className='flex flex-grow w-[135px] items-center gap-[5px] px-2.5 py-[5px] relative bg-[#19262e]'>
              <div className='relative w-fit font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                ESTIMATES
              </div>
              <div className='relative flex-1 grow' />
              <div
                className={`relative w-4 h-4 ${
                  isEstimatesEmpty(data)
                    ? "bg-variable-collection-red"
                    : "bg-variable-collection-neongreen"
                } shadow-[0px_0px_4px_#4bc34b40]`}
              />
            </div>
            <div className='flex w-[135px] h-full items-center gap-[5px] px-2.5 py-[5px] relative bg-[#19262e]'>
              <div className='relative w-fit font-2024-project-box-title font-[number:var(--2024-project-box-title-font-weight)] text-white text-[length:var(--2024-project-box-title-font-size)] tracking-[var(--2024-project-box-title-letter-spacing)] leading-[var(--2024-project-box-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-box-title-font-style)]'>
                INVOICES
              </div>
              <div className='relative flex-1 grow h-px' />
              <div
                className={`relative w-4 h-4 ${
                  isInvoicesEmpty(data)
                    ? "bg-variable-collection-red"
                    : "bg-variable-collection-neongreen"
                } shadow-[0px_0px_4px_#4bc34b40]`}
              />
            </div>
          </div>
        </div>
        <div className='flex items-start justify-center gap-5 relative pt-[20px]'>
          <div className='flex flex-col items-start justify-center relative flex-1 grow bg-[#19262e] overflow-x-auto'>
            <div className='flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]'>
              <button
                className={`flex h-10 items-center justify-between gap-2.5 px-2.5 py-0 relative flex-1 grow ${
                  tableSelected === 0
                    ? "bg-variable-collection-2024-table-header"
                    : ""
                }`}
                onClick={() => {
                  setTableSelected(0)
                }}
              >
                <div className='w-[21px]'></div> {/* Blank div on the left */}
                <div className='flex items-center gap-2.5'>
                  <img
                    className='relative flex-[0_0_auto] h-[21px]'
                    alt='quickbooks icon'
                    src='static/img/quickbooks.png'
                  />
                  <div className='relative w-fit font-2024-project-section-title font-[number:var(--2024-project-section-title-font-weight)] text-white text-[length:var(--2024-project-section-title-font-size)] tracking-[var(--2024-project-section-title-letter-spacing)] leading-[var(--2024-project-section-title-line-height)] whitespace-nowrap [font-style:var(--2024-project-section-title-font-style)]'>
                    QUICKBOOKS
                  </div>
                </div>
                <div className='flex items-center gap-2'>
                  {showRefreshButton && (
                    <img
                      className='relative flex-[0_0_auto] h-[21px] cursor-pointer'
                      alt='refresh icon'
                      src='static/refresh.svg'
                      onClick={async (e) => {
                        e.stopPropagation();
                        try {
                          const taskId = data?.task_statuses?.[0]?.task_id;
                          if (taskId) {
                            const response = await fetcher(`/api/qbo/retry-create-task/${taskId}/`, 'POST', {});
                            if (response.success) {
                              refetch(); 
                              setShowRefreshButton(false);
                              console.log('QuickBooks refresh successful');
                            } else {
                              console.error('QuickBooks refresh failed:', response.error);
                            }
                          } else {
                            console.error('No task ID available for QuickBooks refresh');
                          }
                        } catch (error) {
                          console.error('Error refreshing QuickBooks data:', error);
                        }
                      }}
                    />
                  )}
                  {showRefreshButton && (
                    <img
                      className='relative flex-[0_0_auto] h-[21px] cursor-pointer'
                      alt='edit icon'
                      src='static/edit.svg'
                      style={{ filter: 'invert(50%) sepia(100%) saturate(500%) hue-rotate(90deg) brightness(100%) contrast(100%)' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        pushView("manualQBO");
                      }}
                    />
                  )}
                </div>
              </button>
              <div
                className={`flex items-center justify-center gap-2.5 px-2.5 py-0 relative flex-1 self-stretch grow ${
                  tableSelected === 1
                    ? "bg-variable-collection-2024-table-header"
                    : ""
                }`}
                onClick={() => {
                  setTableSelected(1)
                }}
              >
                <img
                  className='relative flex-[0_0_auto]'
                  alt='dropbox icon'
                  src='static/img/dropbox.svg'
                />
                <div className="relative w-fit [font-family:'Roboto-SemiBold',Helvetica] font-semibold text-white text-base tracking-[1.92px] leading-[normal] whitespace-nowrap">
                  DROPBOX
                </div>
              </div>
            </div>
            <div className='relative self-stretch w-full h-px bg-[#00000080]' />
            <div className='justify-center flex-[0_0_auto] flex flex-col items-start relative self-stretch w-full shadow-[0px_0px_28px_20px_#00000026]'>
              <div className='w-full h-max min-h-[400px]'>
                <CustomCommonTable
                  isLoading={isLoading}
                  data={selectedData}
                  columns={selectedColumns}
                  columnVisibility={selectedVisibility}
                  setColumnVisibility={
                    tableSelected === 0
                      ? setColumnQBOVisibility
                      : setColumnDropboxVisibility
                  }
                  rowSelection={rowSelection}
                  enableRowSelection={true}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  sorting={sorting}
                  setSorting={setSorting}
                  columnSizing={columnSizing}
                  setColumnSizing={setColumnSizing}
                  columnOrder={
                    tableSelected === 0 ? columnQBOOrder : columnDropboxOrder
                  }
                  onRowSelectionChange={setRowSelection}
                  isAuthorized={hasRoleAtLeast(role, ROLES.ADMIN)}
                  getContentCell={{}}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col w-[300px] items-start justify-center relative self-stretch bg-[#19262e]'>
            <div className='flex h-10 items-center relative self-stretch w-full bg-variable-collection-2024-table-header'>
              <div className="absolute left-1/2 transform -translate-x-1/2 [font-family:'Roboto-SemiBold',Helvetica] font-semibold text-white text-base text-center tracking-[1.92px] leading-[normal] whitespace-nowrap">
                NOTES
              </div>
              <div
                className={` ml-auto cursor-pointer ${
                  notes === data?.user_note?.note ? "!hidden" : ""
                }`}
                onClick={async () => {
                  if (data?.user_note?.id) {
                    await fetcher(
                      `/api/project/project-notes/${data?.user_note?.id}/`,
                      "PATCH",
                      {
                        id: data?.user_note?.id,
                        project_uuid: data.uuid,
                        note: notes,
                      }
                    )
                  } else {
                    await fetcher("/api/project/project-notes/", "POST", {
                      project_uuid: data.uuid,
                      note: notes,
                    })
                  }

                  await refetch()
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 10'
                  height='1em'
                  className='pr-3'
                  role='img'
                >
                  <path
                    d='M2 5l3.5 3.5L12 2'
                    stroke='#00ee77'
                    strokeWidth='2'
                    strokeMiterlimit='10'
                    strokeLinecap='square'
                  ></path>
                </svg>
                
              </div>
            </div>
            <div className='relative self-stretch w-full h-px bg-[#00000033]' />
            <div className='gap-5 p-2.5 flex-1 grow flex flex-col items-start relative self-stretch w-full shadow-[0px_0px_28px_20px_#00000026] opacity-100'>
              <textarea
                className='w-full h-full bg-transparent border border-customBorder p-[10px]'
                placeholder='Enter your notes here.'
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
              />
            </div>
          </div>
        </div>
        {StatusConfirmation({ openModal, popAllViewsAndClear, popView })}
        <ManualQBOModal
          openModal={openModal}
          popAllViewsAndClear={popAllViewsAndClear}
          data={data}
          refetch={refetch}
        />
      </div>
    )
  }
}

export default withPageContexts(PageComponent, "/project")