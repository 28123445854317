import React, { useEffect, useRef } from "react"
import { Draggable } from "@hello-pangea/dnd"

export function CustomOptionsPanelItem({ column, index }) {
  const [isChecked, setIsChecked] = React.useState(
    column.getIsVisible ? column.getIsVisible() : true
  );

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (column.toggleVisibility) {
      column.toggleVisibility();
    }
  };

  useEffect(() => {
    if (column.getIsVisible) {
      setIsChecked(column.getIsVisible());
    }
  }, [column]);

  return (
    <Draggable draggableId={column.id} index={index}>
      {(provided, snapshot) => {
        const style = snapshot.isDragging
          ? {
              ...provided.draggableProps.style,
              left: "15px",
              top: provided.draggableProps.style.height * index + 5,
            }
          : provided.draggableProps.style
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={style}
          >
            <div className='inline-flex items-center gap-2.5 relative'>
              <img
                className='relative w-4 h-3.5'
                alt='Drag icon'
                src='static/dragIcon.svg'
              />
              <label className='relative w-4 h-4'>
                <input
                  type='checkbox'
                  className='peer sr-only'
                  checked={isChecked}
                  onChange={handleChange}
                />
                <div className='w-full h-full bg-gray-500 peer-checked:bg-variable-collection-neongreen peer-checked:shadow-green-glow transition-all duration-300'></div>
              </label>
              <div className='relative w-fit uppercase font-2024-filter-option font-[number:var(--2024-filter-option-font-weight)] text-variable-collection-white text-[length:var(--2024-filter-option-font-size)] tracking-[var(--2024-filter-option-letter-spacing)] leading-[var(--2024-filter-option-line-height)] whitespace-nowrap [font-style:var(--2024-filter-option-font-style)]'>
                {column.columnDef.header()}
              </div>
            </div>
          </div>
        )
      }}
    </Draggable>
  )
}
