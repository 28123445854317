import { migrateData } from '../utils/migrateData'
import { fetcher, allowFetching, refreshToken } from '../fetcher'
import debounce from '../utils/debounce'
import { jwtDecode } from 'jwt-decode'
import eventEmitter from '../eventEmitterSingleton'

const keysToExclude = new Set(["email", "access_token", "refresh_token", "role", "name", "permissions", "ally-supports-cache"])
const CURRENT_VERSION = 10

let tokenRefreshTimer = null;

const clearTokenRefreshTimer = () => {
  if (tokenRefreshTimer) {
    clearTimeout(tokenRefreshTimer)
    tokenRefreshTimer = null
  }
}

const scheduleTokenRefresh = (token) => {
  // Clear any existing timer first
  clearTokenRefreshTimer()
  
  try {
    const decoded = jwtDecode(token)
    const currentTime = Date.now()
    const expTime = decoded.exp * 1000
    const buffer = 5 * 60 * 1000
    if (expTime - currentTime < buffer) {
      refreshToken()
    } else {
      const refreshTime = expTime - buffer - currentTime
      tokenRefreshTimer = setTimeout(refreshToken, refreshTime)
    }
  } catch (error) {
    console.log("Couldn't schedule token refresh: ", error)
  }
}

// Listen for logout events to clear the timer
eventEmitter.on("logout", clearTokenRefreshTimer)

function setLocalStorageFromJSON(jsonObject) {

  Object.entries(jsonObject).forEach(([key, value]) => {
    try {
      localStorage.setItem(key, value)
    } catch (error) {
      console.error(`Error setting ${key} in localStorage: `, error)
    }
  })
}

const attemptLoadUserData = async (retryCount = 3) => { 
  try {
    const response = await fetcher("/api/warriors/user_local_storage/", "GET", null, {}, false);
    let data;

    try {
      data = JSON.parse(JSON.parse(response["local_storage_data"]));

      if (data && data.version) {
        data = migrateData(data);
      }
      
      setLocalStorageFromJSON(data.data);

    } catch (error) {
      console.log("No previous data found. Proceeding...");
    }

    allowFetching();

  } catch (error) {
    console.log("Error on attempt", 4 - retryCount)
    await fetcher("/api/warriors/user_local_storage/", "POST", {}, {}, false)
    if (retryCount > 0) {
      await attemptLoadUserData(retryCount - 1)
    } else {
      console.log("Failed after several retries")
      throw new Error("Maximum retries exceeded")
    }
  }
};

const syncLocalStorageWithBackend = async () => {
  const localStorageData = {}
  Object.keys(localStorage).forEach(key => {
    if (!keysToExclude.has(key)) {
      localStorageData[key] = localStorage.getItem(key)
    }
  })

  const filteredData = Object.keys(localStorageData)
    .filter(key => !keysToExclude.has(key))
    .reduce((obj, key) => {
      obj[key] = localStorageData[key]
      return obj
    }, {})

  const dataToSync = {
    version: CURRENT_VERSION,
    data: filteredData
  }

  try {
    const response = await fetcher("/api/warriors/user_local_storage/", "POST", JSON.stringify(dataToSync))
    return response
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
  }
}

const debouncedSync = debounce(syncLocalStorageWithBackend, 10000)

export { attemptLoadUserData, debouncedSync as syncLocalStorageWithBackend, scheduleTokenRefresh }
