import { createContext, useContext, useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { createColumnHelper } from "@tanstack/react-table"
import { createColumn } from "../utils/helpers"
import { useSyncedLocalStorageState } from "../hooks/useSyncedLocalStorageState"
import { statuses, getLabelFromValue } from "../statuses"
import IconCircleCaretDown from "../components/icons/icon-circlecaretdown"

const columnHelper = createColumnHelper()

const today = new Date()
const year = today.getFullYear()
const janFirst = new Date(year, 0, 1)
const decFirst = new Date(year, 11, 31)

const DashboardContext = createContext()

export const DashboardProvider = ({ children }) => {
  const pageTitle = `Projects`
  const [selectedRow, setSelectedRow] = useState(null)
  const [expandedRow, setExpandedRow] = useState(null)
  const selectedRowRef = useRef(selectedRow)
  const expandedRowRef = useRef(expandedRow)
  const [highlightUUID, setHighlightUUID] = useState("")

  //TODO: This was a fix for the Next.js issue with the selectedRow not updating. Possibly remove
  useEffect(() => {
    selectedRowRef.current = selectedRow
  }, [selectedRow])

  //TODO: This was a fix for the Next.js issue with the expandedRowRef not updating. Possibly remove
  useEffect(() => {
    expandedRowRef.current = expandedRow
  }, [expandedRow])

  const [clientFilterColumn, setClientFilterColumn] = useState(null)
  const [projectFilterColumn, setProjectFilterColumn] = useState(null)
  const [statusFilterColumn, setStatusFilterColumn] = useState(null)
  const [yearFilterColumn, setYearFilterColumn] = useState(null)

  const defaultColumns = [
    createColumn(columnHelper, "status", "Status", row => row["status"], 
      (info) => getLabelFromValue(info.renderValue()), 
      { minWidth: 60, filterFn: "filterForMultipleStatuses" }
    ),
    createColumn(columnHelper, "project_id", "Project ID", row => row["project_id"], 
      (info) => (
        <div className='flex gap-1 justify-center h-full items-center'>
          <Link
            className='underline cursor-pointer'
            to={{ pathname: `/project`, search: `?uuid=${info.row.original.uuid}&key=${new Date().getTime()}` }}
          >
            {info.getValue()}
          </Link>
          <div
            className='flex flex-col items-center'
            onClick={(e) => {
              const currentSelectedRow = selectedRowRef.current
              const currentExpandedRow = expandedRowRef.current
              if (currentSelectedRow === info.row.id) {
                if (currentExpandedRow === info.row.id) {
                  setExpandedRow(null)
                } else {
                  setExpandedRow(info.row.id)
                }

                setSelectedRow(null)
                setHighlightUUID(null)
              } else {
                setExpandedRow(info.row.id)
                setSelectedRow(info.row.id)
                setHighlightUUID(null)
              }
            }}
          >
            <IconCircleCaretDown width={16} />
          </div>
        </div>
      ), 
      { minWidth: 60 }
    ),
    createColumn(columnHelper, "client_name", "Client Name", row => row["client_name"], null, { minWidth: 60 }),
    createColumn(columnHelper, "project_name", "Project Name", row => row["project_name"], null, { minWidth: 60 }),
    createColumn(columnHelper, "shipping_responsibility", "Shipping", row => row["shipping_responsibility"], 
      (info) => {
        const value = info.getValue();
        if (!value) return value;
        return value.toLowerCase() === "thumbwar" ? value.toUpperCase() : value.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      }, 
      { minWidth: 60 }
    ),
    createColumn(columnHelper, "po_number", "PO Number", row => row["po_number"], null, { minWidth: 60 }),
    createColumn(columnHelper, "project_event", "Project Event", row => row["project_event"], null, { minWidth: 60 }),
    createColumn(columnHelper, "owner_name", "Owner Name", row => row["owner_name"], null, { minWidth: 60 }),
    createColumn(columnHelper, "project_managers_names", "Managers Name", row => row["project_managers_names"], 
      (info) => info.getValue().join(", "), 
      { minWidth: 100 }
    ),
    createColumn(columnHelper, "Estimates", "Est", row => row?.original?.has_quickbooks_estimate, null, { minWidth: 60 }),
    createColumn(columnHelper, "Invoices", "Inv", row => row?.original?.has_quickbooks_invoice, null, { minWidth: 60 }),
    createColumn(columnHelper, "reoccurring_project", "Recurring", row => row["reoccurring_project"], 
      (info) => info.getValue().toString(), 
      { minWidth: 60 }
    ),
    createColumn(columnHelper, "user_note", "Notes", row => row["user_note"]?.note, null, { minWidth: 60 }),
    createColumn(columnHelper, "year", "Year", row => row["year"], 
      null, 
      { minWidth: 60, filterFn: (row, columnId, filterValue) => !filterValue || row.getValue(columnId) === filterValue }
    ),
  ];

  const defaultColumnsVisibility = {
    shipping_responsibility: false,
    po_number: false,
    project_event: false,
    owner_name: false,
    reoccurring_project: false,
    user_note: true,
    year: true,
  }

  const [selectedStatus, setSelectedStatus] = useSyncedLocalStorageState(
    `${pageTitle}:selectedStatus`,
    { defaultValue: statuses[1] }
  )
  const [selectedFromDate, setSelectedFromDate] = useSyncedLocalStorageState(
    `${pageTitle}:selectedFromDate`,
    { defaultValue: janFirst }
  )
  const [selectedToDate, setSelectedToDate] = useSyncedLocalStorageState(
    `${pageTitle}:selectedToDate`,
    { defaultValue: decFirst }
  )
  const [clientFilterColumnEnabled, setClientFilterColumnEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:clientFilterColumnEnabled`, {
      defaultValue: false,
    })
  const [projectFilterColumnEnabled, setProjectFilterColumnEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:projectFilterColumnEnabled`, {
      defaultValue: false,
    })
  const [statusFilterColumnEnabled, setStatusFilterColumnEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:statusFilterColumnEnabled`, {
      defaultValue: false,
    })
  const [fromDateFilterColumnEnabled, setFromDateFilterColumnEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:fromDateFilterColumnEnabled`, {
      defaultValue: false,
    })
  const [toDateFilterColumnEnabled, setToDateFilterColumnEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:toDateFilterColumnEnabled`, {
      defaultValue: false,
    })
    const [yearFilterColumnEnabled, setYearFilterColumnEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:yearFilterColumnEnabled`, {
      defaultValue: false,
    })
  const [hideCancelledEnabled, setHideCancelledEnabled] =
    useSyncedLocalStorageState(`${pageTitle}:hideCancelledEnabled`, {
      defaultValue: true,
    })
  const [clientFilterTerm, setClientFilterTerm] = useSyncedLocalStorageState(
    `${pageTitle}:clientFilterTerm`,
    { defaultValue: "" }
  )
  const [projectFilterTerm, setProjectFilterTerm] = useSyncedLocalStorageState(
    `${pageTitle}:projectFilterTerm`,
    { defaultValue: "" }
  )
  const [yearFilterTerm, setYearFilterTerm] = useSyncedLocalStorageState(
    `${pageTitle}:yearFilterTerm`,
    { defaultValue: year }
  )
  const [columnSizing, setColumnSizing] = useSyncedLocalStorageState(
    `${pageTitle}:columnSizing`,
    { defaultValue: "" }
  )
  const [sorting, setSorting] = useSyncedLocalStorageState(
    `${pageTitle}:sorting`,
    { defaultValue: [] }
  )
  const [columnVisibility, setColumnVisibility] = useSyncedLocalStorageState(
    `${pageTitle}:columnVisibility`,
    { defaultValue: defaultColumnsVisibility }
  )
  const [globalFilter, setGlobalFilter] = useSyncedLocalStorageState(
    `${pageTitle}:globalFilters`,
    { defaultValue: "" }
  )
  const [columnOrder, setColumnOrder] = useSyncedLocalStorageState(
    `${pageTitle}:columnOrder`,
    {
      defaultValue: Object.keys(defaultColumns).map(
        (d) => defaultColumns[d].id
      ),
    }
  )
  const [tableInstance, setTableInstance] = useState(null)
  
  

  useEffect(() => {
    if (tableInstance) {
      tableInstance.getHeaderGroups().forEach((headerGroup) =>
        headerGroup.headers.forEach((header) => {
          switch (header.id) {
            case "client_name":
              console.log("setting client filter column")
              setClientFilterColumn(header.column)
              break
            case "project_name":
              setProjectFilterColumn(header.column)
              break
            case "status":
              setStatusFilterColumn(header.column)
              break
            case "year":
              console.log("setting year filter column")
              setYearFilterColumn(header.column)
              break
            default:
              break
          }
        })
      )
    }
  }, [tableInstance])
  useEffect(() => {
    if (clientFilterColumn && tableInstance) {
      const filterValue =
        clientFilterColumnEnabled && clientFilterTerm ? clientFilterTerm : null
      clientFilterColumn.setFilterValue(filterValue)
    }
  }, [
    tableInstance,
    clientFilterColumn,
    clientFilterTerm,
    clientFilterColumnEnabled,
  ])

  useEffect(() => {
    if (projectFilterColumn) {
      const filterValue =
        projectFilterColumnEnabled && projectFilterTerm
          ? projectFilterTerm
          : null
      projectFilterColumn.setFilterValue(filterValue)
    }
  }, [projectFilterColumn, projectFilterTerm, projectFilterColumnEnabled])

  useEffect(() => {
    const statuses = [
      "pending",
      "awarded",
      "invoice-ready",
      "invoicing",
      "invoiced",
      "canceled",
      "completed",
    ]
    const statusesIfHideEnabled = hideCancelledEnabled
      ? statuses.filter((status) => status !== "canceled")
      : statuses
    const statusFilteredTerm =
      selectedStatus && statusFilterColumnEnabled
        ? [selectedStatus.title]
        : statusesIfHideEnabled
    if (statusFilterColumn)
      statusFilterColumn.setFilterValue(statusFilteredTerm)
  }, [
    statusFilterColumn,
    statusFilterColumnEnabled,
    selectedStatus,
    hideCancelledEnabled,
  ])

  useEffect(() => {
    if (yearFilterColumn && tableInstance) {
      const filterValue = yearFilterColumnEnabled && yearFilterTerm ? yearFilterTerm : null
      yearFilterColumn.setFilterValue(filterValue)
    }
  }, [tableInstance,yearFilterColumn, yearFilterTerm, yearFilterColumnEnabled])

  const states = {
    defaultColumns,
    tableInstance,
    setTableInstance,
    selectedStatus,
    setSelectedStatus,
    selectedFromDate,
    setSelectedFromDate,
    selectedToDate,
    setSelectedToDate,
    clientFilterColumn,
    setClientFilterColumn,
    projectFilterColumn,
    setProjectFilterColumn,
    statusFilterColumn,
    setStatusFilterColumn,
    yearFilterColumn,
    setYearFilterColumn,
    clientFilterColumnEnabled,
    setClientFilterColumnEnabled,
    projectFilterColumnEnabled,
    setProjectFilterColumnEnabled,
    statusFilterColumnEnabled,
    setStatusFilterColumnEnabled,
    yearFilterColumnEnabled,
    setYearFilterColumnEnabled,
    fromDateFilterColumnEnabled,
    setFromDateFilterColumnEnabled,
    toDateFilterColumnEnabled,
    setToDateFilterColumnEnabled,
    hideCancelledEnabled,
    setHideCancelledEnabled,
    clientFilterTerm,
    setClientFilterTerm,
    projectFilterTerm,
    setProjectFilterTerm,
    yearFilterTerm,
    setYearFilterTerm,
    globalFilter,
    setGlobalFilter,
    sorting,
    setSorting,
    columnVisibility,
    setColumnVisibility,
    columnSizing,
    setColumnSizing,
    selectedRow,
    setSelectedRow,
    expandedRow,
    setExpandedRow,
    highlightUUID,
    setHighlightUUID,
    columnOrder,
    setColumnOrder,
  }

  return (
    <DashboardContext.Provider value={states}>
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => useContext(DashboardContext)
