// import debounce from './utils/debounce'
class NetworkError extends Error {
  constructor(message, statusCode, details) {
    super(message)
    this.name = this.constructor.name
    this.statusCode = statusCode
    this.details = details
  }
  toString() {
    return JSON.stringify({
      name: this.name,
      message: this.message,
      statusCode: this.statusCode,
      details: this.details,
      stack: this.stack
    }, null, 2);
  }
}

let fetchAllowedResolver;
let fetchAllowedPromise = new Promise(resolve => {
  fetchAllowedResolver = resolve;
});
let isFetchingAllowed = false


const resetFetchPromise = () => {
  if (!isFetchingAllowed) {
    fetchAllowedPromise = new Promise(resolve => {
      fetchAllowedResolver = resolve;
    });
  }
}

export const allowFetching = () => {
  if (!isFetchingAllowed) {
    isFetchingAllowed = true
    fetchAllowedResolver() 
  }
}

export const pauseFetching = () => {
  isFetchingAllowed = false
  resetFetchPromise() 
};
import eventEmitter from './eventEmitterSingleton'

const getCookie = (name) => {
  let cookieValue = null
  if (document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split("")
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}


export const refreshToken = async () => {
  console.log("Refreshing token...")
  if(!localStorage.getItem("refresh_token")) {
    throw new Error("No refresh token found...")
  }
  const requestBody = {
    refresh: localStorage.getItem("refresh_token")
  }
  try {
    const response = await makeFetchRequest("/api/auth/refresh-token", {
      method: 'POST',
      body: requestBody
    }, false, true);  
    const data = await response.json();
    localStorage.setItem("access_token", data.access);
  } catch (error) {
    console.log("Could not refresh token. Logging out.");
    console.error(error);
    throw error; 
  }
}

const makeFetchRequest = async (url, options = {}, requiresAuth = true, isRefreshAttempt = false) => {
  if (typeof window === "undefined") {
    return Promise.resolve([])
  }
  
  if(requiresAuth) {
    await fetchAllowedPromise
  }

  const fullUrl = url.startsWith('http') ? url : `${import.meta.env.VITE_BASE_API_URL}${url}`
  const token = localStorage.getItem("access_token")
  
  const defaultHeaders = {
    "Authorization": `Bearer ${token}`,
    "X-CSRFToken": getCookie("csrftoken"),
  }

  if (options.json !== false) {
    defaultHeaders['Content-Type'] = 'application/json'
  }

  const finalOptions = {
    method: 'GET',
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers
    }
  }

  if (finalOptions.body && typeof finalOptions.body === 'object' && options.json !== false) {
    finalOptions.body = JSON.stringify(finalOptions.body)
  }

  const response = await fetch(fullUrl, finalOptions)

  if (!response.ok) {
    if (response.status === 401) {
      if (isRefreshAttempt) {
        const errorBody = await response.json().catch(() => ({ message: "Error parsing JSON" }))
        const error = new NetworkError(errorBody.name ? errorBody.name[0] : response.statusText, response.status, errorBody)
        alert(`Logging out: Authentication failed during token refresh.\nDetails: ${error.toString()}`)
        eventEmitter.emit("logout")
        throw error
      }
      try {
        await refreshToken()
        return makeFetchRequest(url, options, requiresAuth, true)
      } catch (refreshError) {
        console.error("Refresh token failed:", refreshError)
        alert(`Logging out: Failed to refresh authentication token.\nDetails: ${refreshError.toString()}`)
        eventEmitter.emit("logout")
        throw refreshError
      }
    } else {
      const errorBody = await response.json().catch(() => ({ message: "Error parsing JSON" }))
      throw new NetworkError(errorBody.name ? errorBody.name[0] : response.statusText, response.status, errorBody)
    }
  }

  return response
}

export const fetcher = async (url, method = 'GET', body = null, queryParams = {}, requiresAuth = true) => {
  const queryString = new URLSearchParams(queryParams).toString()
  const urlWithQuery = queryString ? `${url}?${queryString}` : url

  const response = await makeFetchRequest(urlWithQuery, {
    method,
    body,
  }, requiresAuth)

  return response.json()
}

export const downloadFile = async (url, filename, requiresAuth = true) => {
  const response = await makeFetchRequest(url, {
    json: false
  }, requiresAuth)

  const blob = await response.blob()
  const downloadUrl = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = downloadUrl
  a.download = filename || 'downloadedFile'
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(downloadUrl)
  a.remove()
}

export const fetchAllPages = async (url, queryParams = {}, requiresAuth = true, pageSize = 10) => {
  let allResults = [];
  const params = { ...queryParams, page_size: pageSize };
  let nextPage = `${url}?${new URLSearchParams(params).toString()}`;
  
  try {
    const firstResponse = await fetcher(nextPage, 'GET', null, {}, requiresAuth);
    console.log('First page response:', firstResponse);
    
    if (Array.isArray(firstResponse)) {
      return firstResponse;
    }
    
    if (!firstResponse.results) {
      return firstResponse;
    }
    
    allResults = [...firstResponse.results];
    nextPage = firstResponse.next;
    
    while (nextPage) {
      try {
        if (nextPage.startsWith('http')) {
          const urlObj = new URL(nextPage);
          nextPage = urlObj.pathname + urlObj.search;
        }
        
        const response = await fetcher(nextPage, 'GET', null, {}, requiresAuth);
        console.log('Next page response:', response);
        allResults = [...allResults, ...response.results];
        nextPage = response.next;
      } catch (error) {
        console.error('Error fetching next page:', error);
        break;
      }
    }
    
    return {
      count: allResults.length,
      next: null,
      previous: null,
      results: allResults
    };
  } catch (error) {
    console.error('Error in fetchAllPages:', error);
    throw error;
  }
}