import "./styles/globals.css"
import "./styles/tw-app.css"

import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import SidebarAlt from "./components/SidebarAlt"
import {
  attemptLoadUserData,
  scheduleTokenRefresh,
} from "./services/syncService"
import { UserProvider } from "./contexts/UserContext"
import eventEmitter from "./eventEmitterSingleton"
import Home from "./pages/Home"
import Dashboard from "./pages/Dashboard"
import Management from "./pages/Management"
import Reporter from "./pages/Reporter"
import Project from "./pages/Project"
import PrivacyPolicy from "./pages/Privacy"
import EULA from "./pages/EULA"
const originalWarn = console.warn.bind(console.warn)
console.warn = (msg) => {
  if (
    msg.includes(
      "If you do not provide children, you must specify an aria-label"
    )
  ) {
    return
  }
  originalWarn(msg)
}

const App = () => {
  const [isReady, setIsReady] = useState(false)
  const [queryClient] = useState(() => new QueryClient())

  useEffect(() => {
    const handleLogout = () => {
      console.trace("handling logout")
      localStorage.clear()
      if (window.location.pathname !== "/") {
        window.location.href = "/"
      }
    }

    eventEmitter.on("logout", handleLogout)

    return () => {
      eventEmitter.off("logout", handleLogout)
    }
  }, [])

  useEffect(() => {
    const runAsync = async () => {
      await attemptLoadUserData()
      const token = localStorage.getItem("access_token")
      if (token) {
        scheduleTokenRefresh(token)
      }
      setIsReady(true)
    }

    if (window.location.pathname !== "/") {
      // if (
      //   !localStorage.getItem("access_token") ||
      //   !localStorage.getItem("refresh_token") ||
      //   !localStorage.getItem("role")
      // ) {
      //   eventEmitter.emit("logout")
      //   setIsReady(true)
      //   console.log("logging out")
      // } else {
        runAsync()
      // }
    } else {
      setIsReady(true)
    }
  }, [])

  if (!isReady) {
    return (
      <div className='absolute flex h-full w-full items-center justify-center'>
        <img
          src='https://twcdn.s3-us-west-2.amazonaws.com/management/TW-cropped.png'
          alt='logo'
          width={64}
          height={64}
          className='fade-in-out'
        />
      </div>
    )
  } else {
    return (
      <GoogleOAuthProvider clientId='151068287858-sm34tqu01tc7fb7kme22ua1n0ae5mjcm.apps.googleusercontent.com'>
        <QueryClientProvider client={queryClient}>
          <UserProvider>
            <Router>
              <Main />
            </Router>
          </UserProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    )
  }
}

const Main = () => {
  const location = useLocation()
  const showSidebar = [
    "/dashboard",
    "/management",
    "/reporter",
    "/project",
    "/test2",
  ].includes(location.pathname)

  return (
    <div className='h-screen sidebar-layout bg-variable-collection-2024-background-color '>
      {showSidebar && <SidebarAlt />}
      <div className='overflow-auto w-full h-screen relative'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/management' element={<Management />} />
          <Route path='/reporter' element={<Reporter />} />
          <Route path='/project' element={<Project />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/eula' element={<EULA />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
