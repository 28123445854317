// ReporterContext.js

import React, { createContext, useContext, useState, useEffect } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { createColumn } from "../utils/helpers"
import { useSyncedLocalStorageState } from "../hooks/useSyncedLocalStorageState"
import { formatDistance } from "date-fns"

const columnHelper = createColumnHelper()

const ReporterContext = createContext()

export const defaultLabelsColumns = [
  createColumn(columnHelper, "label_name", "Label", row => row["label_name"]),
  createColumn(columnHelper, "count", "Count", row => row["count"]),
  createColumn(columnHelper, "sunset_date", "Sunset", row => row["sunset_date"]),
];

export const defaultSystemsColumns = [
  {
    id: "selectionBox",
    index: 0,
    header: ({ table }) => (
      <div className='flex items-center justify-center w-full'>
        <input
          type='checkbox'
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </div>
    ),
  },
  createColumn(columnHelper, "_id", "ID", row => row["_id"]),
  createColumn(columnHelper, "system_name", "System Name", row => String(row["system_name"])),
  createColumn(columnHelper, "label_name", "Label", row => row["label_name"]),
  createColumn(columnHelper, "notification_channel", "Channel", row => row["notification_channel"]),
  createColumn(columnHelper, "temporary_notification_channel", "Temp Channel", row => row["temporary_notification_channel"]),
  createColumn(columnHelper, "status", "Status", row => row["status"]),
  createColumn(columnHelper, "node_status", "Node Status", row => row["node_status"]),
  createColumn(columnHelper, "last_heartbeat_received", "Last Heartbeat", row => row["last_heartbeat_received"], (info) => {
    const value = info.getValue();
    if (value === null) {
      return "Never";
    }
    
    const now = new Date();
    return formatDistance(new Date(value), now, {
      addSuffix: true,
    });
  }),
  createColumn(columnHelper, "is_alive", "Aws Status", row => String(row["is_alive"])),
  createColumn(columnHelper, "first_flipped", "First Flipped", row => row["first_flipped"]),
  createColumn(columnHelper, "flip_count", "Flip Count", row => row["flip_count"]),
  createColumn(columnHelper, "enable_alert", "Enable Alert", row => row["enable_alert"]),
  createColumn(columnHelper, "notification_disabled_at", "Notification Disabled At", row => row["notification_disabled_at"]),
  createColumn(columnHelper, "failed_notifications", "Failed Notifications", row => row["failed_notifications"]),
  createColumn(columnHelper, "last_payload_received", "Last Payload Received", row => row["last_payload_received"]),
  createColumn(columnHelper, "node_first_flipped", "Node First Flipped", row => row["node_first_flipped"]),
  createColumn(columnHelper, "node_flip_count", "Node Flip Count", row => row["node_flip_count"]),
  createColumn(columnHelper, "node_enable_alert", "Node Enable Alert", row => row["node_enable_alert"]),
  createColumn(columnHelper, "node_notification_disabled_at", "Node Notification Disabled At", row => row["node_notification_disabled_at"]),
  createColumn(columnHelper, "is_muted", "Is Muted", row => row["is_muted"]),
  createColumn(columnHelper, "outage_muted_at", "Outage Muted At", row => row["outage_muted_at"]),
  createColumn(columnHelper, "node_is_alive", "Vpn Status", row => row["node_is_alive"]),
  createColumn(columnHelper, "sunset_date", "Sunset", row => row["sunset_date"]),
];

export const defaultSystemsColumnVisibility = {
  selectionBox: true,
  last_heartbeat_received: false,
  is_alive: false,
  first_flipped: false,
  flip_count: false,
  enable_alert: false,
  notification_disabled_at: false,
  failed_notifications: false,
  last_payload_received: false,
  node_first_flipped: false,
  node_flip_count: false,
  node_enable_alert: false,
  node_notification_disabled_at: false,
  is_muted: false,
  outage_muted_at: false,
  node_is_alive: false,
  sunset_date: true,
}
export const defaultLabelsColumnVisibility = {
  uuid: false,
}

export const ReporterProvider = ({ children }) => {
  const [headerSelected, setHeaderSelected] = useState(false)
  const pageTitle = "Reporter"
  const [columnSizing, setColumnSizing] = useSyncedLocalStorageState(
    `${pageTitle}:columnSizing`,
    { defaultValue: "" }
  )

  const [sorting, setSorting] = useSyncedLocalStorageState(
    `${pageTitle}:sorting`,
    {
      defaultValue: [],
    }
  )

  const [globalFilter, setGlobalFilter] = useSyncedLocalStorageState(
    `${pageTitle}:globalFilters`,
    { defaultValue: "" }
  )

  const [columnSystemsOrder, setColumnSystemsOrder] =
    useSyncedLocalStorageState(`${pageTitle}:columnSystemsOrder`, {
      defaultValue: Object.keys(defaultSystemsColumns).map(
        (d) => defaultSystemsColumns[d].id
      ),
    })

  const [columnLabelsOrder, setColumnLabelsOrder] = useSyncedLocalStorageState(
    `${pageTitle}:columnLabelsOrder`,
    {
      defaultValue: Object.keys(defaultLabelsColumns).map(
        (d) => defaultLabelsColumns[d].id
      ),
    }
  )

  const [columnSystemsVisibility, setColumnSystemsVisibility] =
    useSyncedLocalStorageState(`${pageTitle}:columnSystemsVisibility`, {
      defaultValue: defaultSystemsColumnVisibility,
    })
  const [columnLabelsVisibility, setColumnLabelsVisibility] =
    useSyncedLocalStorageState(`${pageTitle}:columnLabelsVisibility`, {
      defaultValue: defaultLabelsColumnVisibility,
    })
  const [intervalMs, setIntervalMs] = useSyncedLocalStorageState(
    `${pageTitle}:intervalMs`,
    { defaultValue: 30000 }
  )
  const [tableSelected, setTableSelected] = useSyncedLocalStorageState(
    `${pageTitle}:tableSelected`,
    { defaultValue: 0 }
  )

  return (
    <ReporterContext.Provider
      value={{
        globalFilter,
        setGlobalFilter,
        sorting,
        setSorting,
        columnSizing,
        setColumnSizing,
        intervalMs,
        tableSelected,
        setTableSelected,
        columnSystemsOrder,
        setColumnSystemsOrder,
        columnLabelsOrder,
        setColumnLabelsOrder,
        columnSystemsVisibility,
        setColumnSystemsVisibility,
        columnLabelsVisibility,
        setColumnLabelsVisibility,
        defaultSystemsColumns,
        headerSelected,
        setHeaderSelected,
      }}
    >
      {children}
    </ReporterContext.Provider>
  )
}
export const useReporterContext = () => useContext(ReporterContext)
